import React from 'react';
import { theme } from '../styles/theme';

/* Styles */
import {
  StyledHighlightedColor,
} from '../styles/homePage.styles';

/* Icons */
import FacebookIcon from '../assets/images/icons/socials/facebook-icon.svg';
import InstagramIcon from '../assets/images/icons/socials/instagram-icon.svg';
import TikTokIcon from '../assets/images/icons/socials/tiktok-icon.svg';

/* Alternative texts */
export const sliderAlt1 = 'wynajem-fotobudki-360';
export const photoBoothAlt = 'wynajem-fotobudki-360';

/* Headings */
export const mainHeader = 'Wynajem fotobudki 360';
export const ourTeam = 'Nasz Team';
export const whyUs = 'Dlaczego my?';
export const howItWorks = 'Jak działa fotobudka 360?';
export const photoBoothInAction = 'Fotobudka 360 w akcji!';
export const photoBoothOffer = 'Oferta fotobudki 360';
export const priceListHeading = 'Cennik';
export const reservation = 'Zapytaj o wycenę';

export const heroTextOne = 'Uchwyć wspomnienia';
export const heroTextTwo = () => (
  <>
    w
    {' '}
    <StyledHighlightedColor>360</StyledHighlightedColor>
    {' '}
    stopniach!
  </>
);

export const paragraphs = [
  {
    text: 'Wejdź w świat slow-motion i niekończącej się zabawy dzięki fotobudce 360 stopni!',
  },
  {
    text: `Z nami nudne kadry idą w odstawkę, a zabawa się nakręca z wyjątkową mocą
    uchwycenia każdej miny, ekspresji i dzikich popisów.
    Stań na podświetlanej platformie, nasza obrotowa kamera obróci się wokół Ciebie jak
    taneczna parkietowa kula disco, a w tle wyleci miliony monet banknotów, bańki
    mydlane lub konfetti. Pozostaw na Tik-toku, Instagramie, Youtubie lub Facebook'u
    wspaniałą pamiątkę dla Pary Młodej czy solenizanta lub skorzystaj z ich zasięgów, aby
    promować swoją markę.`,
  },
  {
    text: `Hit imprezowy - Zapomnij o nudnych zdjęciach, gdzie wszyscy wyglądają jak manekiny
    w sklepie z ubraniami. Wynajmij fotobudkę 360 na wesele, urodziny czy event firmowy
    już teraz!`,
  },
];

export const aboutTeam = 'Ekipa, która zamiesza na Twojej imprezie - z uśmiechem na ustach i energią do zabawy, rozkręcimy każde wydarzenie! Możemy być w składzie 4, 3 lub 2-osobowym ;)';

export const services = [
  {
    title: 'Jakość usługi i produktu',
    description: 'Zestaw fotobudki jest produkcji polskiej. Nie pracujemy na tanich, awaryjnych chińskich produktach. Gwarantujemy wysoką jakość filmów.',
  },
  {
    title: 'Profesjonalna obsługa',
    description: 'Uśmiechnięci i otwarci profesjonaliści. Dbałość o detale oraz komunikacja w języku polskim i angielskim.',
  },
  {
    title: 'Możliwość brandingu',
    description: 'Możliwość oklejenia platformy własnym logo, personalizacja graficzna nakładki filmików, ścianki wystawiennicze, rollupy, gadżety reklamowe.',
  },
];

export const steps = [
  {
    description: 'Elegancki czerwony dywan, blask lamp, LED migocze? Jest i ona, fotobudka! Zapraszamy na platformę.',
  },
  {
    description: 'Wszystkie lampy na bohaterów. Gadżety dobrane? Pistolety naładowane? To 3-2-1 START! Obrotowa kamera w ruch - Czas na SHOW. Wszystkie kocie ruchy mile widziane, ważny jest ruch! To nie chwila dla manekina! ;)',
  },
  {
    description: 'Wszyscy cali? To rach-ciach, się zadziało. Bumerangi, slow-motion, fast-motion. Filmik gotowy! Chwila moment i już udostępnione, a jak? QR kodem, proszę Pani, proszę Pana :)',
  },
];

export const offer = [
  {
    description: `
      \n * Platforma 360,
      \n * Oświetlenie lampami LED,
      \n * Słupki odgradzające z czerwonymi taśmami,
      \n * Czerwony dywan,
      \n * Gadżety m.in.: pistolet na bańki, pistolet na pieniądze,
      \n * Profesjonalna obsługa, kontakt w języku polskim i angielskim,
      \n * Udostępnianie materiałów na bieżąco,
      \n * Personalizacja graficzna slajdów i wybór utworów muzycznych.
      \n **Opcjonalnie:**
      \n * Branding stanowiska: oklejenie platformy, ścianki wystawiennicze, rollupy, gadżety reklamowe
      
    `,
  },

];

export const priceList = [
  {
    title: 'CZAS WYNAJMU \n I OBSŁUGI',
    time: '2h',
    price: '1000,00 zł',
  },
  {
    title: 'CZAS WYNAJMU \n I OBSŁUGI',
    time: '3h',
    price: '1300,00 zł',
  },
  {
    title: 'CZAS WYNAJMU \n I OBSŁUGI',
    time: '4h',
    price: '1500,00 zł',
  },
  {
    title: 'EVENTY \n DLA FIRM',
    price: 'wycena \n indywidualna',
  },
];

export const priceAdditionalTextOne = 'MOŻLIWOŚĆ PRZEDŁUŻENIA WYNAJMU, KAŻDA KOLEJNA GODZINA - 200 ZŁ';
export const priceAdditionalTextTwo = '*cena dotyczy zleceń na terenie Warszawy i bliskich okolic (do 50km). Wyjazdy poza ten obszar są wyceniane indywidualnie';

export const questions = 'Masz pytania? Dzwoń lub pisz :)';

export const phoneNumber = () => (
  <>
    Rafał
    {' '}
    <a href={`tel:${theme.contacts.phone}`}>{theme.contacts.phone}</a>
  </>
);

export const emailAddress = () => (
  <a href={`mailto:${theme.contacts.email}`}>{theme.contacts.email}</a>
);

export const siteUrl = () => (
  <a href={theme.contacts.siteUrl}>Fotobudki360.net</a>
);

export const socialMediaLinks = [
  {
    name: 'facebook',
    icon: FacebookIcon,
    link: 'https://www.facebook.com/people/Fotobudki360net/61555384902467',
  },
  {
    name: 'instagram',
    icon: InstagramIcon,
    link: 'https://www.instagram.com/fotobudki360_net',
  },
  {
    name: 'tiktok',
    icon: TikTokIcon,
    link: 'https://www.tiktok.com/@fotobudki360.net?_t=8jDsbi8mi2q&_r=1',
  },
];
