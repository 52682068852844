/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-use-before-define */

/* Plugins */
import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

/* Components */
import {
  Container,
  Heading,
  Article,
  SEO,
  Section,
  Figure,
  Video,
  // PriceBox,
  // DownloadButton,
} from '../components/components';

import { GoogleMap, ContactForm } from '../components/Communication';

/* Styles */
import {
  StyledText,
  StyledTextWrapper,
  StyledHeroTextContainer,
  StyledHeroTextOne,
  StyledHeroTextTwo,
  StyledOneColumnContainer,
  StyledTwoColumsContainer,
  StyledThreeColumsContainer,
  // StyledFourColumsContainer,
  StyledSocialLink,
  StyledSocialLinksContainer,
} from '../styles/homePage.styles';

/* Messages */
import { theme } from '../styles/theme';
import * as messages from '../messages/home.messages';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(name: { eq: "hero-img" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      mp4File: file(name: { eq: "fotobudka-360-video-1" }, extension: { eq: "mp4" }) {
        publicURL
      }
      team: allFile(
        filter: { relativeDirectory: { eq: "images/team" } }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      services: allFile(
        filter: { relativeDirectory: { eq: "images/icons/services" } }
      ) {
        edges {
          node {
            id
            name
            publicURL
          }
        }
      }
      steps: allFile(
        filter: { relativeDirectory: { eq: "images/icons/steps" } }
      ) {
        edges {
          node {
            id
            name
            publicURL
          }
        }
      }
      photoBooth: file(name: { eq: "fotobudka-360" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      videoPoster: file(name: { eq: "fotobudka-360-poster" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      videos: allFile(
        filter: { relativeDirectory: { eq: "videos/mp4" } }
      ) {
        edges {
          node {
            id
            publicURL
          }
        }
      }
      socialMedia: allFile(
        filter: { relativeDirectory: { eq: "images/icons/socials" } }
      ) {
        edges {
          node {
            id
            publicURL
          }
        }
      }
    }
  `);

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    autoPlay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <SEO />
      <main>
        {/* Hero image */}
        <Slider {...settings}>
          <GatsbyImage
            image={data.hero.childImageSharp.gatsbyImageData}
            alt={messages.sliderAlt1}
          />
        </Slider>

        {/* Hero Text */}
        <StyledHeroTextContainer>
          <StyledHeroTextOne>{messages.heroTextOne}</StyledHeroTextOne>
          <StyledHeroTextTwo>{messages.heroTextTwo()}</StyledHeroTextTwo>
        </StyledHeroTextContainer>

        {/* Introduction section */}
        <Section>
          <Container>
            <Article>
              <Heading headingLevel="h1" title={messages.mainHeader} />
              <StyledTextWrapper>
                {messages.paragraphs.map(({ text }) => <Markdown children={text} key={text} />)}
              </StyledTextWrapper>
            </Article>
          </Container>
        </Section>

        {/* Team section */}
        <Section id="nasz-team">
          <Container>
            <Article>
              <Heading headingLevel="h2" title={messages.ourTeam} highlightedLastWord />
              <StyledTextWrapper className="mb25-40"><Markdown children={messages.aboutTeam} /></StyledTextWrapper>
              <StyledTwoColumsContainer className="no-padding">
                {data.team.edges.map(({ node }) => (
                  <Figure
                    team
                    key={node.id}
                    alt={node.name}
                    image={node.childImageSharp.gatsbyImageData}
                    title={node.name}
                  />
                ))}
              </StyledTwoColumsContainer>
            </Article>
          </Container>
        </Section>

        {/* Why us section */}
        <Section id="dlaczego-my">
          <Container>
            <Heading headingLevel="h2" title={messages.whyUs} highlightedLastWord />
            <StyledThreeColumsContainer>
              {data.services.edges.map(({ node }, index) => (
                <Figure
                  key={node.id}
                  alt={node.name}
                  image={node.publicURL}
                  title={messages.services[index].title}
                  description={messages.services[index].description}
                />
              ))}
            </StyledThreeColumsContainer>
          </Container>
        </Section>

        {/* How it works section */}
        <Section id="jak-dziala">
          <Container>
            <Heading headingLevel="h2" title={messages.howItWorks} />
            <StyledTwoColumsContainer>
              <Video
                loop
                autoPlay
                poster={data.videoPoster.childImageSharp.gatsbyImageData.placeholder.fallback}
                videoSrc={data.mp4File.publicURL}
              />
              <StyledOneColumnContainer>
                {data.steps.edges.map(({ node }, index) => (
                  <Figure
                    smaller
                    key={node.id}
                    alt={node.name}
                    image={node.publicURL}
                    description={messages.steps[index].description}
                  />
                ))}
              </StyledOneColumnContainer>
            </StyledTwoColumsContainer>
          </Container>
        </Section>

        {/* Offer section */}
        <Section id="oferta">
          <Container>
            <Heading headingLevel="h2" title={messages.photoBoothOffer} />
            <StyledTwoColumsContainer reverse>
              <StyledOneColumnContainer>
                <Article>
                  {messages.offer.map(({ description }, index) => (
                    <Markdown rehypePlugins={[rehypeRaw]} children={description} key={index} />
                  ))}
                </Article>
              </StyledOneColumnContainer>
              <GatsbyImage
                image={data.photoBooth.childImageSharp.gatsbyImageData}
                alt={messages.photoBoothAlt}
              />
            </StyledTwoColumsContainer>
          </Container>
        </Section>

        {/* Price section */}
        {/* <Section id="cennik">
          <Container>
            <Heading headingLevel="h2" title={messages.priceListHeading} />
            <StyledFourColumsContainer>
              {messages.priceList.map(({
                title,
                time,
                price,
              }) => (
                <PriceBox
                  key={price}
                  title={title}
                  time={time}
                  price={price}
                />
              ))}
            </StyledFourColumsContainer>
            <StyledText className="bold">{messages.priceAdditionalTextOne}</StyledText>
            <StyledText>{messages.priceAdditionalTextTwo}</StyledText>
            <DownloadButton title="Pobierz ofertę" />
          </Container>
        </Section> */}

        {/* Videos section */}
        <Section>
          <Container>
            <Heading headingLevel="h2" title={messages.photoBoothInAction} />
            <StyledThreeColumsContainer>
              {data.videos.edges.map(({ node }) => (
                <Video
                  key={node.id}
                  autoPlay={false}
                  videoSrc={node.publicURL}
                />
              ))}
            </StyledThreeColumsContainer>
          </Container>
        </Section>

        {/* Contact section */}
        <Section id="zapytaj-o-wycene">
          <Container>
            <Heading headingLevel="h2" title={messages.reservation} highlightedLastWord />
            <StyledTwoColumsContainer reverse>
              <StyledOneColumnContainer className="contact-details">
                <Heading headingLevel="h3" title="Kontakt" />
                <StyledText>{messages.questions}</StyledText>
                <StyledText>{messages.phoneNumber()}</StyledText>
                <StyledText>{messages.emailAddress()}</StyledText>
                <StyledSocialLinksContainer>
                  {messages.socialMediaLinks.map(({ name, icon, link }) => (
                    <StyledSocialLink
                      key={name}
                      href={link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={icon}
                        alt={name}
                      />
                    </StyledSocialLink>
                  ))}
                </StyledSocialLinksContainer>
                <StyledText>{messages.siteUrl()}</StyledText>
                <StyledText>{`${theme.contacts.address}, ${theme.contacts.postalCode}`}</StyledText>
                <GoogleMap />
              </StyledOneColumnContainer>
              <ContactForm />
            </StyledTwoColumsContainer>
          </Container>
        </Section>
      </main>
    </>
  );
};

export default IndexPage;
