import styled, { css, keyframes } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-60%);
  }
  to {
    opacity: 1
    transform: translateY(0%);
  }
`;

const slideLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-30%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
`;

export const StyledTextWrapper = styled.div`
  text-align: justify;

  ${breakpoint('md')`
    text-align: center;
    p {
      font-size: 2rem;
    }
  `}
`;

export const StyledText = styled.p`
  text-align: justify;

  ${breakpoint('md')`
    text-align: center;
  `}

  &.bold {
    font-weight: 700;
    margin: 10px 0;

    ${breakpoint('md')`
     font-size: 1.8rem;
     margin-top: 0;
    `}
  }
`;

export const StyledSpan = styled.span`
  display: block;
  text-align: center;
  position: relative;
  line-height: 1.2;
`;

export const StyledHeroTextContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Pattaya', sans-serif;
  width: 100%;
  height: calc(100% - 104px);
  top: 104px;
  left: 0;
  right: 0;
  bottom: 0;

  ${breakpoint('lg')`
    height: calc(100% - 110px);
    top: 110px;
  `}
`;

export const StyledHeroTextOne = styled(StyledSpan)`
  color: #fff;
  font-size: 15vw;
  text-shadow: 0px 0px 25px #353535;
  animation: ${slideDown} 0.6s ease-in-out;
  
  ${breakpoint('md')`
    font-size: 7rem;
  `}

  ${breakpoint('lg')`
    font-size: 7vw;
    white-space: nowrap;
  `} 
`;

export const StyledHeroTextTwo = styled(StyledSpan)`
  opacity: 0;
  color: #000;
  font-size: 11vw;
  white-space: nowrap;
  text-shadow: 0px 0px 25px #fff;
  animation: ${slideLeft} 0.6s ease forwards;
  animation-delay: 0.8s;
  margin: 20px 0 90px;

  ${breakpoint('md')`
    font-size: 6rem;
  `}
  
  ${breakpoint('lg')`
     font-size: 6vw;
  `} 
`;

export const StyledHighlightedColor = styled(StyledSpan)`
  display: inline-block;
  text-shadow: 0px 0px 25px rgb(0, 0, 0);
  font-weight: 700;
  color: rgb(255 172 77);
`;

export const StyledOneColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  ${breakpoint('lg')`
    gap: 0;
  `}

  &.contact-details {
    align-items: flex-start;
    gap: 0;

    h3 {
      font-size: 2.8rem;
      margin-bottom: 30px;
    }

    p {
      font-size: 2rem;
    }
  }
`;

export const StyledTwoColumsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding-top: 40px;
  gap: 40px;

  ${breakpoint('lg')`
    grid-template-columns: 1fr 1fr;
    padding-top: 60px;
  `}

  ${({ reverse }) => reverse && css`
    div:first-child {
      order: 1;

      ${breakpoint('lg')`
        order: -1;
      `}
    }
  `}

  ${({ reverse }) => reverse && css`
    div:first-child {
      order: 1;

      ${breakpoint('lg')`
        order: -1;
      `}
    }
  `}
`;

export const StyledThreeColumsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding-top: 40px;
  gap: 40px;

  ${breakpoint('md')`
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 60px;
  `}
`;

export const StyledFourColumsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 20px 0;
  gap: 30px;

  ${breakpoint('md')`
    padding: 40px 0;
    grid-template-columns: repeat(2, 1fr);
  `}

  ${breakpoint('xl')`
    padding: 60px 0;
    grid-template-columns: repeat(4, 1fr);
  `}
`;

export const StyledSocialLinksContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const StyledSocialLink = styled.a`
  display: inline-block;
  width: 50px;

  img {
    width: 100%;
  }
`;
